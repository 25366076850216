import * as React from 'react';

const MeetTheTeamCTA = () => (
  <article className="w-full max-w-5xl mx-auto my-auto">
    <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
      Meet the <br />
      <span className="text-brand-orange">
        Frontline <br /> Removals
      </span>{' '}
      <br />
      team
    </p>
  </article>
);

export default MeetTheTeamCTA;
