import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import * as React from 'react';
import { IoIosCall } from 'react-icons/io';

const Team = () => {
  const { site, allTeamMembersJson } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          phone
          phoneFormatted
        }
      }
      allTeamMembersJson {
        nodes {
          id
          image {
            childImageSharp {
              fluid(maxWidth: 256) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          name
          title
        }
      }
    }
  `);
  return (
    <article className="flex flex-wrap w-full max-w-6xl px-6 py-4 mx-auto">
      {allTeamMembersJson.nodes.map((person) => (
        <div key={person.id} className="flex w-full mt-24 md:w-1/2 lg:w-1/3">
          <div className="w-full mx-6 text-white rounded bg-brand-orange">
            <div
              className="w-32 h-32 mx-auto overflow-hidden rounded-full"
              style={{ transform: `translateY(-50%)` }}
            >
              <Image
                fluid={person.image.childImageSharp.fluid}
                alt={`${person.name}: ${person.title}`}
              />
            </div>
            <div className="px-6 py-8 -mt-24 text-center">
              <p aria-hidden className="mt-4 font-semibold">
                {person.name}
              </p>
              <p aria-hidden>{person.title}</p>
              <p className="mt-4 font-semibold">
                <a
                  href={`tel:${site.siteMetadata.phone}`}
                  className="flex items-center justify-center"
                >
                  <IoIosCall className="opacity-75" />
                  <span className="ml-1">
                    {site.siteMetadata.phoneFormatted}
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      ))}
    </article>
  );
};

export default Team;
