import * as React from 'react';

import { Hero, Layout, SEO } from '../components';
import CTA from '../components/meet-the-team/meet-the-team-cta';
import FirstSection from '../components/meet-the-team/meet-the-team-first-section';
import HeroImage from '../components/meet-the-team/meet-the-team-hero-image';
import TeamMembers from '../components/meet-the-team/team-members';

const MeetTheTeamPage = () => (
  <Layout
    formName="team-page-bottom-form"
    formTitle="Buying or hiring? Contact us today"
  >
    <SEO
      title="Interstate Removalists - Meet Our Team | Frontline Removals"
      description="Frontline Removals and Storage is a team of trusted professionals. We take the utmost care when moving your goods and we've been doing it for over 26 years!"
    />
    <Hero image={<HeroImage />} cta={<CTA />} />
    <FirstSection />
    <TeamMembers />
  </Layout>
);

export default MeetTheTeamPage;
