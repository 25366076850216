import * as React from 'react';

const MeetTheTeamFirstSection = () => (
  <article className="flex flex-wrap max-w-6xl px-6 py-4 mx-auto mt-20">
    <div className="w-full md:w-1/3">
      <h1 className="text-5xl font-semibold leading-none uppercase heading font-display">
        <span className="inline-block">Our </span>
        <br />
        <span className="inline-block">Team</span>
      </h1>
    </div>
    <div className="w-full mt-6 md:mt-0 md:w-2/3">
      <div className="rich-text">
        <p>
          Frontline Removals and Storage is a team of trusted professionals. We
          take the utmost care when moving your goods and we've been doing it
          for over {new Date().getFullYear() - 1995} years!
        </p>
        <p>Meet the team who take the headache out of home relocation:</p>
      </div>
    </div>
  </article>
);

export default MeetTheTeamFirstSection;
